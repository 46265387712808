import { LayoutComponent } from '../layout/layout.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersComponent } from './users/users.component';
import { ConfigComponent } from './config/config.component';
import { CompanyComponent } from './company/company.component';
import { VerificationComponent } from './verifications/verification.component';

export const routes = [
    {
        path: 'app',
        component: LayoutComponent,
        children: [
            { path: 'dashboard', component: DashboardComponent},
            { path: 'verifications', component: VerificationComponent},
            { path: 'users', component: UsersComponent },
            { path: 'companies', component: CompanyComponent },
            { path: 'config', component: ConfigComponent }
        ]
    },

    // Not lazy-loaded routes
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    { path: 'login', component: LoginComponent },

    // Not found
    { path: '**', redirectTo: 'app/verifications' }

];


