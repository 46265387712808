// Modules
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { VerificationsModule } from './verifications/verifications.module';

// Components
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersComponent } from './users/users.component';

import { AddUserComponent } from './users/add/user-add-modal.component';
import { DetailsUserComponent } from './users/details/user-details-modal.component';
import { ConfigComponent } from './config/config.component';
import { AddListsComponent } from './config/lists/lists-add-modal.component';
import { CompanyComponent } from './company/company.component';
import { AddCompanyComponent } from './company/add/company-add-modal.component';
import { DetailsCompanyComponent } from './company/details/company-details-modal.component';

// Services
import { MenuService } from '../core/menu/menu.service';
import { EventService } from './verifications/verifications.service';
import { UsersService } from './users/users.service';
import { ConfigService } from './config/config.service';
import { CompanyService } from './company/company.service';

// Models
import { routes } from './routes';



@NgModule({
    imports: [
        SharedModule,
        VerificationsModule,
        RouterModule.forRoot(routes)
        
    ],
    declarations: [
      LoginComponent,
      DashboardComponent,
      UsersComponent,
      AddUserComponent,
      DetailsUserComponent,
      ConfigComponent,
      AddListsComponent,
      CompanyComponent,
      AddCompanyComponent,
      DetailsCompanyComponent,
    ],
    entryComponents: [
        AddUserComponent,
        DetailsUserComponent,
        AddCompanyComponent,
        AddListsComponent
    ],
    providers: [
        UsersService,
        EventService,
        ConfigService,
        CompanyService
    ],

    exports: [
        RouterModule
    ]
})

export class RoutesModule {
    constructor(
        public menuService: MenuService,
        public user: UsersService
    ) {
        menuService.addMenu();
    }
}
