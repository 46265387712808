export const SideMenu = [
    {
        'text': 'TEST ENVIRONMENT',
        'link': '/app/verifications',
        'icon': 'icon-alert',
        'active' : false
    },
    {
        'text': 'Full Investigations',
        'link': '/app/verifications',
        'icon': 'icon-magnifier',
        'active' : false
    },
    {
        'text': 'Bank Verifications',
        'link': '/app/verification/bank',
        'icon': 'icon-magnifier',
        'active' : false
    },
    {
        'text': 'Consumer Traces',
        'link': '/app/verification/trace',
        'icon': 'icon-magnifier',
        'active' : false
    },
    {
        'text': 'User Admin',
        'link': '/app/users',
        'icon': 'icon-user',
        'active' : false
    },
    {
        'text': 'System Configuration',
        'link': '/app/config',
        'icon': 'icon-settings',
        'active' : false
    }];
