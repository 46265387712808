// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  cloudFunctions: 'https://knowmyclient-1501076246449.web.app/api/',
  firebase: {
    apiKey: "AIzaSyD-JZdlIs8uTIFiJ4t2K2y4qVGP12fyzmU",
    authDomain: "itoo-4e779.firebaseapp.com",
    databaseURL: "https://itoo-4e779.firebaseio.com",
    projectId: "itoo-4e779",
    storageBucket: "itoo-4e779.appspot.com",
    messagingSenderId: "542361608928",
    appId: "1:542361608928:web:000d3946f9c17b4ff4e685"
}
};


