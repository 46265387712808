import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';


import { AddComponent } from './add.component';
import { DetailsComponent } from './details.component';
import { VerificationComponent } from './verification.component';
import { UpdateComponent } from './update.component';
import { RequestComponent } from './request.component';

// Services
import { VerificationService } from './verifications.service';
import { PdfService } from './pdf.service';

// ];

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    AddComponent,
    DetailsComponent,
    VerificationComponent,
    UpdateComponent,
    RequestComponent
  ],
  providers: [
    VerificationService,
    PdfService
  ],
  exports: [
    RouterModule
  ],
  entryComponents: [
    AddComponent,
    DetailsComponent,
    UpdateComponent,
    RequestComponent
  ]
})
export class VerificationsModule { }

