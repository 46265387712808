import { Injectable, Component} from '@angular/core';
import { AngularFirestore} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import { User } from '../../shared/models/user';
import { UserService } from '../../core/user/user.service';
import { whenRendered } from '@angular/core/src/render3';

@Injectable()
export class UsersService {
    user: any
    userList: any
    loading = false;
    constructor(
        public afs: AngularFirestore,
        public afAuth: AngularFireAuth,
        public userService: UserService
    ) {
        this.user = afAuth.authState;
        console.log(this.user);
      }

    public async getUsers(): Promise<any> {

        this.user = await this.userService.user
        if (this.userService.user.isAdmin) {
            console.log('user is admin')
            return this.afs.collection('users').valueChanges()
        }
        else {
            console.log('user is not admin')
            return this.afs.collection('users', ref => ref.where('id', '==', this.userService.user.id)).valueChanges()
        }


        //     })   (user => {ß
        //         console.log(user)
        //         return this.afs.collection('users').valueChanges()
        //     })
        // }) 
        
    }

    public getUsers2(): Promise<any> {
        console.log('getUsers2 fired')
        return this.afs.collection('log').add({
            "context": "users",
            "action": "get_all"
        })
    }

    public createUser(user) {
        user.status = 'Created';
        user.created = {
            uid: this.afAuth.auth.currentUser.uid,
            name: this.afAuth.auth.currentUser.displayName,
            time: new Date().getTime()
        };
        return (this.afs.collection('users').add(user));
    }

    public resetPassword(user) {
        return this.afAuth.auth.sendPasswordResetEmail(user.email);
    }
    public updateUser(user) {
        user.updated = {
            uid: this.afAuth.auth.currentUser.uid,
            name: this.afAuth.auth.currentUser.displayName,
            time: new Date().getTime()
        };
        return (this.afs.collection('users').doc(user.id).update(user));
    }

}
